<template>
  <div>
    <a href @click="clicked($event)">
      <i class="far fa-square" v-if="value === 0"></i>
      <i class="far fa-check-square" v-else-if="value === 1"></i>
      <i class="far fa-minus-square" v-else></i>
    </a>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
export default {
  name: 'CheckAllButton',
  props: {
    value: Number,
  },
  emits: ['click'],
  methods: {
    clicked(event) {
      event.preventDefault()
      event.stopPropagation()
      return this.$emit('click', { value: this.value, })
    },
  },
}
</script>
<style scoped>
a {
  color: var(--ion-color-dark);
  display: inline-block;
  font-size: 14pt;
}
</style>
