<template>
  <div class="page">
    <div>
      <loading-gif :loading-name="synthesisLoading"></loading-gif>
      <div v-if="!isSynthesisLoading && initialized">
        <ion-card color="dark">
          <ion-card-content>
            <div>
              <i class="fa fa-info-circle"></i>
              Vous pouvez retrouver ici toutes les inscriptions que vous avez déjà effectuées.
            </div>
          </ion-card-content>
        </ion-card>
        <div v-if="synthesisElements.length === 0" class="field-line">
          <ion-card color="dark">
            <ion-card-content>
              <ion-card-title>Aucune inscription.</ion-card-title>
            </ion-card-content>
          </ion-card>
        </div>
        <div v-else>
          <div
            v-for="elt in synthesisElements"
            v-bind:key="elt.index"
            class="synthesis-element"
          >
            <ion-row class="synthesis-element-header" :style="getStyle(elt)">
              <ion-col size="9">
                <div class="small">{{ elt.youthHome.name }}</div>
                {{ elt.seanceType.name }} - {{ elt.period.name }}
                <div class="small">
                  <counter-label label="inscription" :counter="elt.inscriptionsCount"></counter-label>
                </div>
              </ion-col>
              <ion-col size="3" class="text-right">
                <ion-button @click.prevent="showInscriptions(elt)" color="dark" size="small">
                  <span v-if="!areInscriptionsVisible(elt)"><i class="fa fa-chevron-down"></i> Voir</span>
                  <span v-else><i class="fa fa-chevron-up"></i> Cacher</span>
                </ion-button>
              </ion-col>
            </ion-row>
            <loading-gif :loading-name="getInscriptionsLoadingName(elt)"></loading-gif>
            <ion-list v-show="areInscriptionsVisible(elt)" class="inscriptions-list">
              <div v-if="getInscriptions(elt).length === 0">
                Pas d'inscriptions sur la période
              </div>
              <div v-else>
                <ion-row
                  v-for="inscription of getInscriptions(elt)"
                  :key="inscription.id"
                  class="inscription-line"
                  :class="getInscriptionStyle(inscription)"
                >
                  <ion-col size="5">
                    {{ getSeanceName(inscription) }}
                  </ion-col>
                  <ion-col size="4">
                    {{ inscription.individual.firstAndLastName() }}
                    <div v-if="inscription.workshops.length">
                      <span v-for="workshop of inscription.workshops" :key="workshop.id">
                        {{ workshop.name }}
                      </span>
                    </div>
                  </ion-col>
                  <ion-col size="3" class="text-right ins-status">
                    <span v-if="inscription.paymentWarning">
                      À payer
                    </span>
                    <span v-else>
                      <span v-if="inscription.confirmed">
                        <span v-if="inscription.cancelled">
                          <span class="badge badge-warning">
                            <i class="fas fa-exclamation-circle"></i> Annulée
                          </span>
                        </span>
                        <span v-else-if="inscription.refused">
                          <span class="badge badge-waiting" v-if="inscription.waiting">
                            <i class="fas fa-th-list"></i> Sur liste d'attente
                          </span>
                           <span class="badge badge-danger" v-else>
                            <i class="fas fa-exclamation-triangle"></i> Refusée
                          </span>
                        </span>
                        <span v-else>
                          <span class="badge badge-success">
                            <i class="fa fa-check"></i> Confirmée
                          </span>
                        </span>
                      </span>
                      <span v-else>
                        <span v-if="inscription.cancelled">
                          <span class="badge badge-dark">
                            <i class="fa fa-comment-dots"></i> Annulation en cours de traitement
                          </span>
                        </span>
                        <span v-else>
                          <span class="badge badge-dark">
                            <i class="fa fa-comment-dots"></i> En cours de traitement
                          </span>
                        </span>
                      </span>
                    </span>
                  </ion-col>
                </ion-row>
              </div>
            </ion-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IonButton, IonList, IonCol, IonRow, IonCardContent, IonCardTitle, IonCard
} from '@ionic/vue'
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeSeanceInscription, makeSeanceSynthesisElt } from '@/types/youth'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel'

export default {
  name: 'entity-inscriptions-summary',
  components: {
    LoadingGif, CounterLabel,
    IonButton, IonList, IonCol, IonRow, IonCardContent, IonCardTitle, IonCard,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    reload: Boolean,
  },
  emits: ['reloaded'],
  data() {
    return {
      synthesisElements: [],
      synthesisLoading: 'inscriptions-summary-loading',
      inscriptionsMap: {},
      visibilityMap: {},
      initialized: false,
    }
  },
  computed: {
    entityIndividuals() {
      return this.entity.getMainIndividuals()
    },
    isSynthesisLoading() {
      return store.getters.loading && store.getters.loading[this.synthesisLoading]
    },
  },
  created() {
    this.loadSynthesis(this.entity)
  },
  watch: {
    entity: function() {
      this.loadSynthesis(this.entity)
    },
    reload: function(value) {
      if (value) {
        this.loadSynthesis(this.entity)
        this.$emit('reloaded')
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'startEntityEdition', 'endEntityEdition']),
    async loadSynthesis(entity) {
      if (entity && entity.id) {
        this.startLoading(this.synthesisLoading)
        const url = '/portal/api/entity-synthesis/' + entity.id + '/?show_with_inscriptions=1'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.synthesisElements = resp.data.map((elt, index) => makeSeanceSynthesisElt(elt, index))
          this.inscriptionsMap = {}
          this.visibilityMap = {}
          this.initialized = true
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(this.synthesisLoading)
      }
    },
    async loadInscriptions(entity, elt) {
      if (entity && entity.id) {
        const loadingName = this.getInscriptionsLoadingName(elt)
        this.startLoading(loadingName)
        const url = '/portal/api/entity-inscriptions-list/' + entity.id + '/' + elt.youthHome.id + '/' +
            elt.seanceType.id + '/' + elt.period.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          const inscriptions = resp.data.map((elt) => makeSeanceInscription(elt))
          this.inscriptionsMap[elt.index] = inscriptions
          this.inscriptionsMap = { ...this.inscriptionsMap, }
          this.visibilityMap[elt.index] = true
          this.visibilityMap = { ...this.visibilityMap, }
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(loadingName)
      }
    },
    getStyle(elt) {
      if (elt.period.backgroundColor) {
        return {
          'background': elt.period.backgroundColor,
          'color': elt.period.textColor || '#000',
        }
      } else if (elt.seanceType.backgroundColor) {
        return {
          'background': elt.seanceType.backgroundColor,
          'color': elt.seanceType.textColor || '#000',
        }
      } else if (elt.youthHome.backgroundColor) {
        return {
          'background': elt.youthHome.backgroundColor,
          'color': elt.youthHome.textColor || '#000',
        }
      } else {
        return {
          'background': '#2fdf75',
          'color': "#000",
        }
      }
    },
    showInscriptions(elt) {
      if (!this.areInscriptionsVisible(elt)) {
        this.loadInscriptions(this.entity, elt)
      } else {
        this.visibilityMap[elt.index] = false
        this.visibilityMap = { ...this.visibilityMap, }
      }
    },
    areInscriptionsVisible(elt) {
      return !!this.visibilityMap[elt.index]
    },
    getInscriptions(elt) {
      const inscriptions = this.inscriptionsMap[elt.index]
      return inscriptions ? inscriptions : []
    },
    getInscriptionsLoadingName(elt) {
      return 'inscriptions-loading:' + elt.index
    },
    getSeanceName(inscription) {
      if (inscription.seance.fixedFee && !store.getters.options.viewFixedFeeDates) {
        return inscription.seance.getShortName()
      }
      return inscription.seance.name
    },
    getInscriptionStyle(inscription) {
      if (inscription.seance.fixedFee) {
        return 'fixed-fee-seance'
      }
      return ''
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.synthesis-element {
  margin: 20px 10px;
  padding: 5px;
  border: solid 1px var(--ion-color-medium);
}
ion-item.synthesis-element-header {
  font-weight: bold;
  border-bottom: black 1px solid;
}
ion-item.synthesis-element-header:first-child{
  --border-style: none !important;
}
.inscription-line {
  border-top: solid 1px #888;
}
ion-button i {
  margin-right: 5px;
}
.page {
  padding-bottom: 100px;
}
.text-right {
  text-align: right;
}
.buttons-bar {
  text-align: center;
  margin-top: 5px;
}
.badge {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 4px;
  background: var(--ion-color-dark);
  color: var(--ion-color-light);
}
.badge.badge-success{
  background: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}
.badge.badge-danger{
  background: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}
.badge.badge-warning{
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}

.badge.badge-waiting{
  background: #8dfbff;
  color: var(--ion-color-warning-contrast);
}

.fixed-fee-seance {
  background: var(--ion-color-secondary);
  color: #222;
}

.fixed-fee-seance a {
  color: #222;
}


.ins-status .badge {
  text-align: center;
  display: block;
}
</style>
