
import { defineComponent, ref } from "vue"
import {
  IonPage, IonHeader, IonToolbar, IonContent,  IonSegment, IonSegmentButton, IonLabel, IonCard, IonCardContent,
  IonCardTitle, IonButton, IonCardHeader, IonRow, IonCol
} from '@ionic/vue'
import EntityInscriptions from '@/components/EntityInscriptions.vue'
import EntityInscriptionsSummary from '@/components/EntityInscriptionsSummary.vue'
import EntitySelect from '@/components/EntitySelect.vue'
import TopBar from '@/components/TopBar.vue'
import MessageBar from '../components/Controls/MessageBar.vue'
import router from '@/router'
import store from '@/store'
import { getInvoices } from '@/apis/payments'
import { Entity, makeEntity } from '@/types/people'
import { getErrorText } from '@/utils/http'
import { Invoice } from '@/types/payments'
import WarningVersion from "@/components/WarningVersion.vue"

export default defineComponent({
  name: 'InscriptionsTab',
  components: {
    EntityInscriptions, EntityInscriptionsSummary, EntitySelect, TopBar, MessageBar,
    IonHeader, IonToolbar, IonContent, IonPage, IonSegment, IonSegmentButton, IonLabel, IonCard, IonCardContent,
    IonCardTitle, IonButton, IonCardHeader, IonRow, IonCol, WarningVersion,
  },
  setup() {
    const entity = ref(makeEntity())
    const reload = ref(false)
    const refreshDisabled = ref(false)
    let reloadSeances = false
    let reloadInscriptions = false
    const hasUnpaidInvoice = ref(false)
    const segment = ref('new-inscriptions')

    async function checkInvoices(entity: Entity) {
      if (entity && entity.id) {
        try {
          const invoices = await getInvoices(entity)
          hasUnpaidInvoice.value = invoices.filter((elt: Invoice) => !elt.isPaid).length > 0
        } catch (err) {
          await store.dispatch('addError', getErrorText(err))
        }
      }
    }

    const onSeancesReloaded = function() {
      reloadSeances = false
      if (!reloadSeances && !reloadInscriptions) {
        reload.value = false
      }
    }

    const onInscriptionsReloaded = function() {
      reloadInscriptions = false
      if (!reloadSeances && !reloadInscriptions) {
        reload.value = false
      }
    }

    const onEntityChanged = async function(newEntity: Entity) {
      entity.value = newEntity
      checkInvoices(newEntity)
    }

    const refresh = function() {
      if (!reload.value && !refreshDisabled.value) {
        reloadSeances = true
        reloadInscriptions = true
        reload.value = true
      }
    }

    const onInscriptionsSaved = function(event: any) {
      store.commit('reloadSeances')
      refreshDisabled.value = false
      if (event.autoInvoice) {
        store.commit('resetSelectedDay')
        store.commit('refreshInvoice')
        router.push({ name: 'invoices', })
      } else {
        reload.value = true
        segment.value = 'view-inscriptions'
      }
    }

    const onInscriptionsChanged = function(event: any) {
      refreshDisabled.value = event.hasInscriptions
    }

    function onShowInvoices() {
      store.commit('refreshInvoice')
      router.push({ name: 'invoices', })
    }

    return {
      entity, hasUnpaidInvoice, onEntityChanged, onInscriptionsSaved, onShowInvoices, segment, refresh,
      reload, onSeancesReloaded, onInscriptionsReloaded, refreshDisabled, onInscriptionsChanged,
    }
  },
})
