import { Entity } from '../types/people'
import { BackendApi } from '../utils/http'
import { Invoice, makeInvoice } from '../types/payments'

export function getInvoices(entity: Entity): Promise<Invoice[]> {
  return new Promise(
    (resolve, reject) => {
      const url = '/portal/api/entity/' + entity.id + '/invoices/'
      const backendApi = new BackendApi('get', url)
      backendApi.callApi().then(
        (resp) => {
          resolve(resp.data.map((elt: any) => makeInvoice(elt)))
        }
      ).catch(err => {
        reject(err)
      })
    }
  )
}