<template>
  <span>
    <div
      class="seance-limits-indicator"
      v-if="hasGroupLimits"
      :title="groupTitle"
      :class="groupClass"
    >
      {{ groupMinText }}
      <div class="info-limit">{{ groupMinWarning }}</div>
    </div>
    <div
      class="seance-limits-indicator"
      v-if="hasDayLimits"
      :class="dayClass"
    >
      {{ dayMinText }}
      <div class="info-limit">{{ dayMinWarning }}</div>
    </div>
    <div
      class="seance-limits-indicator"
      v-if="hasSeanceGroupLimits"
      :title="seanceGroupTitle"
      :class="seanceGroupClass"
    >
      {{ seanceGroupMinText }}
      <div class="info-limit">{{ seanceGroupMinWarning }}</div>
    </div>
    <div
      class="seance-limits-indicator"
      v-if="hasSeanceLimits"
      :title="seanceTitle"
      :class="seanceClass"
    >
      {{ seanceMinText }}
      <div class="info-limit">{{ seanceMinWarning }}</div>
    </div>
    <div
      class="seance-limits-indicator"
      v-if="hasExcursionGroupLimits"
      :title="excursionGroupTitle"
      :class="excursionGroupClass"
    >
      {{ excursionGroupMinText }}
      <div class="info-limit">{{ excursionGroupMinWarning }}</div>
    </div>
    <div
      class="seance-limits-indicator"
      v-if="hasExcursionLimits"
      :title="excursionTitle"
      :class="excursionClass"
    >
      {{ excursionMinText }}
      <div class="info-limit">{{ excursionMinWarning }}</div>
    </div>
    <div v-if="isFull && allowWaitingList" class="waiting-label">
      {{ waitingLabel }}
    </div>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'SeanceLimitsDisplay',
  components: {
  },
  props: {
    groupLimits: Array,
    dayLimits: Array,
    seanceGroupLimits: Array,
    seanceLimits: Array,
    excursionLimits: Array,
    excursionGroupLimits: Array,
    ageGroup: Object,
    hideRemaining: Boolean,
    allowWaitingList: Boolean,
  },
  data() {
    return {
      limit: false,
      limitLabel: 'Nombre limité de places',
      waitingLabel: 'Vous serez placé sur liste d\'attente',
    }
  },
  watch: {
  },
  computed: {
    groupTitle() {
      const title = this.getLimitsTitle(this.groupLimits)
      if (title) {
        return this.ageGroup.name + ': ' + title
      }
      return ''
    },
    dayTitle() {
      const title = this.getLimitsTitle(this.dayLimits)
      if (title) {
        return 'Journée ' + title
      }
      return ''
    },
    seanceGroupTitle() {
      const title = this.getLimitsTitle(this.seanceGroupLimits)
      if (title) {
        return 'Séance ' + this.ageGroup.name + ': ' + title
      }
      return ''
    },
    seanceTitle() {
      const title = this.getLimitsTitle(this.seanceLimits)
      if (title) {
        return 'Séance: ' + title
      }
      return ''
    },
    excursionGroupTitle() {
      const title = this.getLimitsTitle(this.excursionGroupLimits)
      if (title) {
        return 'Sortie ' + this.ageGroup.name + ': ' + title
      }
      return ''
    },
    excursionTitle() {
      const title = this.getLimitsTitle(this.excursionLimits)
      if (title) {
        return 'Sortie: ' + title
      }
      return ''
    },
    hasGroupLimits() {
      return this.groupLimits.length > 0
    },
    hasDayLimits() {
      return this.dayLimits.length > 0
    },
    hasSeanceGroupLimits() {
      return this.seanceGroupLimits.length > 0
    },
    hasSeanceLimits() {
      return this.seanceLimits.length > 0
    },
    hasExcursionLimits() {
      return this.excursionLimits.length > 0
    },
    hasExcursionGroupLimits() {
      return this.excursionGroupLimits.length > 0
    },
    groupMinText() {
      if (this.hideRemaining) {
        return this.limitLabel
      } else {
        return this.ageGroup.name + ': ' + this.getMinimum(this.groupLimits) + ' places'
      }
    },
    groupMinWarning() {
      return this.getWarnings(this.groupLimits)
    },
    dayMinText() {
      if (this.hideRemaining) {
        return this.limitLabel
      } else {
        return 'Limite: ' + this.getMinimum(this.dayLimits) + ' places'
      }
    },
    dayMinWarning() {
      return this.getWarnings(this.dayLimits)
    },
    seanceGroupMinText() {
      if (this.hideRemaining) {
        return this.limitLabel
      } else {
        return this.ageGroup.name + ': ' + this.getMinimum(this.seanceGroupLimits) + ' places'
      }
    },
    seanceGroupMinWarning() {
      return this.getWarnings(this.seanceGroupLimits)
    },
    seanceMinText() {
      if (this.hideRemaining) {
        return this.limitLabel
      } else {
        return 'Limite: ' + this.getMinimum(this.seanceLimits) + ' places'
      }
    },
    seanceMinWarning() {
      return this.getWarnings(this.seanceLimits)
    },
    excursionGroupMinText() {
      if (this.hideRemaining) {
        return this.limitLabel
      } else {
        return this.ageGroup.name + ': ' + this.getMinimum(this.excursionGroupLimits) + ' places'
      }
    },
    excursionGroupMinWarning() {
      return this.getWarnings(this.excursionGroupLimits)
    },
    excursionMinText() {
      if (this.hideRemaining) {
        return this.limitLabel
      } else {
        return 'Limite: ' + this.getMinimum(this.excursionLimits) + ' places'
      }
    },
    excursionMinWarning() {
      return this.getWarnings(this.excursionLimits)
    },
    groupClass() {
      return this.getCssClass(this.groupLimits)
    },
    dayClass() {
      return this.getCssClass(this.dayLimits)
    },
    seanceGroupClass() {
      return this.getCssClass(this.seanceGroupLimits)
    },
    seanceClass() {
      return this.getCssClass(this.seanceLimits)
    },
    excursionGroupClass() {
      return this.getCssClass(this.seanceGroupLimits)
    },
    excursionClass() {
      return this.getCssClass(this.seanceLimits)
    },
    isFull() {
      const allLimits = [
          this.groupLimits, this.dayLimits, this.seanceGroupLimits, this.seanceLimits,
          this.excursionLimits, this.excursionGroupLimits
      ]
      for (const limits of allLimits) {
        for (const limit of limits) {
          if (limit.inscriptions >= limit.limit) {
            return true
          }
        }
      }
      return false
    },
  },
  methods: {
    getLimitsTitle(limits) {
      let title = ''
      for (const limit of limits) {
        if (limit.limit > 0) {
          if (title) {
            title += ' - '
          }
          if (limit.label) {
            title += limit.label + ' '
          }
          if (!this.hideRemaining) {
            title += '' + limit.inscriptions + '/' + limit.limit
          }
        }
      }
      return title
    },
    getMinimum(limits) {
      let minLimit = 0
      for (const limit of limits) {
        if ((minLimit === 0) || (minLimit > limit.limit)) {
          minLimit = limit.limit
        }
      }
      return minLimit
    },
    getWarnings(limits) {
      let warnings = ''
      let hasLim  = false
      let minRemaining = 0
      for (const limit of limits) {
        const remaining = Math.max(limit.limit - limit.inscriptions, 0)
        if (!hasLim || (remaining < minRemaining)) {
          minRemaining = remaining
          hasLim = true
        }
      }
      if (minRemaining === 0) {
        warnings = 'Complet'
      } else if (minRemaining > 0 && !this.hideRemaining) {
        warnings = 'Reste ' + minRemaining + ' place' + ((minRemaining > 1) ? 's' : '')
      }
      return warnings
    },
    getCssClass(limits) {
      let warning = false
      let full = false
      let overflow = false
      for (const limit of limits) {
        if (limit.inscriptions >= (limit.limit - 5)) {
          warning = true
        }
        if (limit.inscriptions === limit.limit) {
          full = true
        }
        if (limit.inscriptions > limit.limit) {
          overflow = true
        }
      }
      if (overflow) {
        return 'overflow'
      } else if (full) {
        return 'full'
      } else if (warning) {
        return this.hideRemaining ? 'neutral' : 'warning'
      }
      return this.hideRemaining ? 'neutral' : ''
    },
  },
}
</script>
<style>
.seance-limits-indicator {
  border-radius: 2px;
  padding: 1px 4px;
  display: block;
  background: #7db093;
  color: #000;
  font-weight: normal;
  font-size: 13px;
  text-align: center;
  margin: 2px 2px;
  border: solid 1px #222;
}
.seance-limits-indicator .info-limit {
  font-weight: bold;
}
.seance-limits-indicator.warning {
  background: #e2c88a;
}
.seance-limits-indicator.neutral {
  background: #ebebeb;
}
.seance-limits-indicator.full {
  background: #f996b5;
}
.seance-limits-indicator.overflow {
  background: #f996b5;
}
.waiting-label {
  border-radius: 2px;
  padding: 1px 4px;
  display: block;
  font-weight: normal;
  font-size: 11px;
  text-align: center;
  margin: 2px 2px;
  color: #000;
  background: #f996b5;
  border: solid 1px #222;
}
</style>
