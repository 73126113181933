<template>
  <div class="page">
    <div>
      <loading-gif :loading-name="synthesisLoading"></loading-gif>
      <div v-if="!isSynthesisLoading && initialized">
        <ion-card
          class="message-card" color="warning"
          v-for="message in getGeneralUserMessages()"
          :key="message.id"
          v-html="getText(message)"
        >
        </ion-card>
        <div v-if="synthesisElements.length === 0" class="field-line">
          <ion-card color="dark">
            <ion-card-header>
              <ion-card-title>Il n'y a pas de période d'inscription en cours.</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              Contactez directement votre structure si nécessaire.
            </ion-card-content>
          </ion-card>
        </div>
        <div v-else>
          <div v-show="!showInscriptions">
            <ion-card color="dark">
              <ion-card-header>
                <ion-card-title>Inscription aux séances.</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <div v-if="(cancellations.length === 0) && (newInscriptions.length === 0)">
                  Pour chacun de vos enfants, cochez les dates et les séances auxquelles vous souhaitez inscrire
                </div>
                <div v-else>
                  Vous avez sélectionné
                    <counter-label :counter="newInscriptions.length" label="inscription" v-if="newInscriptions.length">
                    </counter-label>
                    <span v-if="cancellations.length && newInscriptions.length"> et </span>
                    <counter-label :counter="cancellations.length" label="annulation" v-if="cancellations.length">
                    </counter-label>
                  <div>
                    Avez-vous coché toutes les séances souhaitées?
                  </div>
                  <div>
                    <ion-button
                      @click.prevent="onShowInscriptions()"
                    >
                      <i class="fa fa-chevron-right"></i> Voir votre sélection
                    </ion-button>
                  </div>
                </div>
              </ion-card-content>
            </ion-card>
            <div
              v-for="elt in synthesisElements"
              v-bind:key="elt.index"
              class="synthesis-element"
            >
              <ion-row class="synthesis-element-header" :style="getStyle(elt)">
                <ion-col size="9">
                  <div class="small">{{ elt.youthHome.name }}</div>
                  {{ elt.seanceType.name }} - {{ elt.period.name }}
                  <div class="small">
                    <counter-label label="inscription" :counter="elt.inscriptionsCount"></counter-label>
                  </div>
                </ion-col>
                <ion-col size="3" class="text-right">
                  <ion-button @click.prevent="showSeances(elt)" color="dark" size="small">
                    <span v-if="!areSeancesVisible(elt)"><i class="fa fa-chevron-down"></i> Voir</span>
                    <span v-else><i class="fa fa-chevron-up"></i> Cacher</span>
                  </ion-button>
                </ion-col>
              </ion-row>
              <div v-if="hasService(elt) && areSeancesVisible(elt)">
                <ion-item
                  text-wrap
                  v-if="getOpening(elt) && !getService(elt).hideOpeningPeriod && getService(elt).allowCancellation"
                >
                  Les inscriptions et annulations sont ouvertes du {{ dateToString(getOpening(elt).openingAt) }} au
                  {{ dateToString(getOpening(elt).closingAt) }}.
                </ion-item>
                <ion-item
                  text-wrap
                  v-if="getOpening(elt) && !getService(elt).hideOpeningPeriod && !getService(elt).allowCancellation"
                >
                  Les inscriptions sont ouvertes du {{ dateToString(getOpening(elt).openingAt) }} au
                  {{ dateToString(getOpening(elt).closingAt) }}.
                </ion-item>
                <ion-item text-wrap v-if="getService(elt).getDescription(false)">
                  {{ getService(elt).getDescription(false) }}
                </ion-item>
                <ion-item
                  class="synthesis-element-message" color="warning"
                  v-if="!getService(elt).autoConfirmation && getService(elt).autoInvoice"
                >
                  Attention! Les inscriptions ne sont valides qu'une fois
                  payées et acceptées par notre équipe.
                </ion-item>
                <ion-item
                  class="synthesis-element-message" color="warning"
                  v-if="!getService(elt).autoConfirmation && !getService(elt).autoInvoice"
                >
                  Attention! Les inscriptions ne sont valides qu'une fois acceptées par notre équipe.
                </ion-item>
                <ion-item
                  class="synthesis-element-message" color="dark"
                  v-if="getService(elt).autoInvoice && getService(elt).showAutoInvoiceMessage"
                >
                  Une fois que vous aurez confirmé vos inscriptions, la facture correspondante sera disponible
                  dans l'onglet "Factures".
                  Vous devez payer cette facture pour que notre équipe puisse accepter votre inscription.
                </ion-item>
              </div>
              <ion-item
                class="synthesis-element-message" color="warning"
                v-for="message in getUserMessages(elt.youthHome)" :key="message.id"
              >
                {{ message.text }}
              </ion-item>
              <ion-list
                v-if="canCreateSeances(elt)"
                v-show="areSeancesLoaded(elt) && areSeancesVisible(elt)"
                class="seances-list"
              >
                <entity-seances
                  :elt="elt"
                  :entity="entity"
                  :youth-home="elt.youthHome"
                  :seance-type="elt.seanceType"
                  :period="elt.period"
                  :service="getService(elt)"
                  @seances-loaded="onSeancesLoaded(elt, $event)"
                  @inscription-changed="onInscriptionChanged"
                  @limits-loaded="onLimitsLoaded"
                ></entity-seances>
              </ion-list>
            </div>
            <ion-card color="dark" v-if="confirmations">
              <ion-card-header>
                <ion-card-title>Inscription aux séances.</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <div>
                  <ion-card-title>
                  Vous avez sélectionné
                    <counter-label :counter="newInscriptions.length" label="inscription" v-if="newInscriptions.length">
                    </counter-label>
                    <span v-if="cancellations.length && newInscriptions.length"> et </span>
                    <counter-label :counter="cancellations.length" label="annulation" v-if="cancellations.length">
                    </counter-label>
                  </ion-card-title>
                  <ion-card-title>
                    Avez-vous coché toutes les séances souhaitées?
                  </ion-card-title>
                  <div>
                    <ion-button
                      color="danger"
                      @click.prevent="onShowInscriptions()"
                    >
                      <i class="fa fa-chevron-right"></i> Voir votre sélection
                    </ion-button>
                  </div>
                </div>
              </ion-card-content>
            </ion-card>
          </div>
          <div v-if="showInscriptions">
            <ion-card color="dark">
              <ion-card-header>
                <ion-card-title>Voici le résumé et les prix des inscriptions souhaités</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <loading-gif :loading-name="seancesCreationLoading"></loading-gif>
                <div v-if="!confirmInProgress">
                  <div>
                    Si cette sélection est bien celle souhaitée
                  </div>
                  <ion-button
                      color="danger"
                      v-if="showInscriptions"
                      @click.prevent="onConfirmInscriptions()"
                  >
                    <i class="fa fa-chevron-right"></i> Confirmer vos inscriptions
                  </ion-button>
                  <hr />
                  <div>
                    Si vous souhaitez modifier celle-ci
                  </div>
                  <ion-button @click.prevent="showInscriptions = false">
                    <i class="fa fa-chevron-left"></i> Retour aux inscriptions
                  </ion-button>
                </div>
              </ion-card-content>
            </ion-card>

            <div v-for="elt of autoInvoiceServices" v-bind:key="elt.index">
              <ion-card color="warning">
                <ion-card-header>
                  <ion-card-title>Facturation</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  Les séances {{ elt.seanceType.name }} - {{ elt.youthHome.name }} seront automatiquement facturées.
                </ion-card-content>
              </ion-card>
            </div>
            <div v-if="hasLimits && hasLimitsCounter">
              <ion-card class="header-warning">
                <ion-card-content>
                Les limites n'incluent pas les inscriptions que vous demandez
                </ion-card-content>
              </ion-card>
            </div>
            <div v-if="cancellations.length > 0"  class="inscriptions-block">
              <div class="header-line ut-cancellations">
                <b>Annulations: {{ cancellations.length }}</b>
              </div>
              <div
                v-for="inscription in cancellations"
                 :key="inscription.getKey()"
                 class="field-line"
              >
                <ion-row class="ut-cancellation">
                  <ion-col cols="7">
                    <span class="label">{{ inscription.seance.seanceType.name }}</span>
                    {{ inscription.seance.name }}
                  </ion-col>
                  <ion-col cols="3" class="text-right">{{ inscription.individual.firstAndLastName() }}</ion-col>
                  <ion-col cols="2" class="text-right">
                    <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                    <div v-if="!isCalculatePricesLoading">
                      <div v-if="!priceError">
                        <div v-if="getMessage(inscription)" class="label">
                          {{ getMessage(inscription) }}
                        </div>
                        <span v-if="hasPrice(inscription)">
                          {{ currency(getPrice(inscription)) }}
                        </span>
                      </div>
                      <span v-else class="inline-div">
                        <i class="fas fa-exclamation-circle" @click="showPriceError()"></i>
                      </span>
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </div>
            <div v-if="newInscriptions.length > 0" class="inscriptions-block">
              <div class="header-line ut-cancellations">
                <b>Inscriptions: {{ newInscriptions.length }}</b>
              </div>
              <div
                v-for="inscription in newInscriptions"
                :key="inscription.getKey()"
                class="field-line"
                :class="getInscriptionStyle(inscription)"
              >
                <ion-row>
                  <ion-col>
                    <span class="label">{{ inscription.seance.seanceType.name }}</span>
                    <span class="label">{{ inscription.seance.youthHome.name }}</span>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <b>{{ getSeanceName(inscription) }}</b>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="6">
                    <b>{{ inscription.individual.firstAndLastName() }}</b>
                  </ion-col>
                  <ion-col>
                    <div>
                      <div v-if="getLimits(inscription)">
                        <seance-limits-display
                          :day-limits="getLimits(inscription).dayLimits"
                          :group-limits="getLimits(inscription).groupLimits"
                          :seance-group-limits="getLimits(inscription).seanceGroupLimits"
                          :seance-limits="getLimits(inscription).seanceLimits"
                          :excursion-group-limits="getLimits(inscription).excursionGroupLimits"
                          :excursion-limits="getLimits(inscription).excursionLimits"
                          :age-group="getLimits(inscription).ageGroup"
                          :hide-remaining="getLimits(inscription).hideRemaining"
                          :allow-waiting-list="getLimits(inscription).allowWaitingList"
                        ></seance-limits-display>
                      </div>
                    </div>
                  </ion-col>
                  <ion-col size="2" class="text-right">
                    <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                    <div v-if="!isCalculatePricesLoading">
                      <span v-if="!priceError">
                        <div v-if="getMessage(inscription)" class="label">
                          {{ getMessage(inscription) }}
                        </div>
                        <span v-if="hasPrice(inscription)">
                          {{ currency(getPrice(inscription)) }}
                        </span>
                        <div v-for="welfare of getWelfareSummary(inscription )" :key="welfare.id">
                          <div class="label label-info">
                            {{ welfare.name }}<br />
                            {{ welfare.getWelfareAmount() }}
                          </div>
                        </div>
                      </span>
                      <span v-else>
                        <i class="fas fa-exclamation-circle" @click="showPriceError()"></i>
                      </span>
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </div>

            <ion-card color="dark">
              <ion-card-header>
                <ion-card-title>Voici le résumé et les prix des inscriptions souhaités</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <loading-gif :loading-name="seancesCreationLoading"></loading-gif>
                <div v-if="!confirmInProgress">
                  <div>
                    Si cette sélection est bien celle souhaitée
                  </div>
                  <ion-button
                      color="danger"
                      v-if="showInscriptions"
                      @click.prevent="onConfirmInscriptions()"
                  >
                    <i class="fa fa-chevron-right"></i> Confirmer vos inscriptions
                  </ion-button>
                  <hr />
                  <div>
                    Si vous souhaitez modifier celle-ci
                  </div>
                  <ion-button @click.prevent="showInscriptions = false">
                    <i class="fa fa-chevron-left"></i> Retour aux inscriptions
                  </ion-button>
                </div>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IonItem, IonButton, IonList, IonCol, IonRow, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
  alertController
} from '@ionic/vue'
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { currency, dateToString } from '@/filters/texts'
import { BackendApi } from '@/utils/http'
import {
  makeEntitySeance, makeSeanceSynthesisElt, SeanceInscription, makeUserMessage, getInscriptionLimitKey
} from '@/types/youth'
import { makePortalService, makePortalOpening } from '@/types/portal'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel'
import EntitySeances from '@/components/EntitySeances'
import SeanceLimitsDisplay from '@/components/SeanceLimitsDisplay'
import store from '@/store'
import { makeSeancePrice } from '@/types/tariffs'
import { htmlText } from "@/utils/html"

export default {
  name: 'entity-inscriptions',
  components: {
    EntitySeances, CounterLabel, LoadingGif, SeanceLimitsDisplay,
    IonItem, IonButton, IonList, IonCol, IonRow, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    reload: Boolean,
  },
  emits: ['inscriptions-saved', 'inscriptions-changed', 'reloaded'],
  data() {
    return {
      seances: [],
      synthesisElements: [],
      synthesisLoading: 'seances-synthesis-loading',
      areSeancesVisibleArray: [],
      areSeancesLoadedArray: [],
      allDiscounts: {},
      userDiscounts: {},
      userMessages: [],
      youthHomeServices: [],
      initialized: false,
      allPrices: {},
      showInscriptions: false,
      forcePriceCalculation: false,
      refundCancellations: {},
      calculatePricesLoadingName: 'calculate-entity-price',
      seancesCreationLoading: 'create-inscriptions',
      synthesisElementsServices: new Map(),
      synthesisElementsOpenings: new Map(),
      priceError: '',
      limitsMap: new Map(),
      hasLimits: false,
      hasLimitsCounter: false,
    }
  },
  computed: {
    seancesRefreshed() {
      return store.getters.seancesRefreshed
    },
    entityIndividuals() {
      return this.entity.getMainIndividuals()
    },
    isCalculatePricesLoading() {
      return store.getters.loading && store.getters.loading[this.calculatePricesLoadingName]
    },
    isSynthesisLoading() {
      return store.getters.loading && store.getters.loading[this.synthesisLoading]
    },
    confirmInProgress() {
      return store.getters.loading && store.getters.loading[this.seancesCreationLoading]
    },
    newInscriptions() {
      const newInscriptions = []
      const avoidDuplicates = new Map()
      for (const seance of this.seances) {
        if (seance.hasNewInscriptions()) {
          for (const individual of this.entity.getMainIndividuals()) {
            if (seance.isIndividualInscriptionNew(individual.id)) {
              const key = '' + seance.id + ':' + individual.id
              if (!avoidDuplicates.has(key)) {
                avoidDuplicates.set(key, 1)
                newInscriptions.push(new SeanceInscription(0, seance, individual))
              }
            }
          }
        }
      }
      return newInscriptions
    },
    cancellations() {
      const cancellations = []
      for (const seance of this.seances) {
        if (seance.hasCancellations()) {
          for (const individual of this.entity.getMainIndividuals()) {
            if (seance.isIndividualInscriptionCancelled(individual.id)) {
              cancellations.push(new SeanceInscription(0, seance, individual))
            }
          }
        }
      }
      return cancellations
    },
    autoInvoiceServices() {
      const services = []
      const processedServices = new Map()
      for (const ins of this.newInscriptions) {
        const key = '' + ins.seance.youthHome.number + '-' + ins.seance.seanceType.id
        if (!processedServices.has(key)) {
          if (this.synthesisElementsServices.has(key)) {
            const service = this.synthesisElementsServices.get(key)
            processedServices.set(key, service)
            if (service.autoInvoice) {
              services.push(
                {
                  index: services.length + 1,
                  seanceType: ins.seance.seanceType,
                  youthHome: ins.seance.youthHome,
                  service: service,
                }
              )
            }
          }
        }
      }
      return services
    },
    confirmations() {
      return (this.cancellations.length) || (this.newInscriptions.length)
    },
  },
  async created() {
    await this.loadPortalYouthHomeServices()
    await this.loadOpenings()
  },
  watch: {
    entity: async function() {
      await this.refresh()
      await this.loadUserMessages()
    },
    reload: async function(value) {
      if (value) {
        await this.refresh()
        await this.loadUserMessages()
        this.$emit('reloaded')
      }
    },
    seancesRefreshed: async function() {
      await this.refresh()
      this.reloadSeancesDone()
    },
    newInscriptions: function() {
      this.onInscriptionsChanged()
    },
    cancellations: function() {
      this.onInscriptionsChanged()
    },

  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations([
        'startLoading', 'endLoading', 'startEntityEdition', 'endEntityEdition', 'reloadSeancesDone'
    ]),
    currency(value) {
      return currency(value)
    },
    dateToString(value) {
      return dateToString(value, 'llll')
    },
    async refresh() {
      await this.loadSynthesis(this.entity)
    },
    async loadSynthesis(entity) {
      if (entity && entity.id) {
        this.startLoading(this.synthesisLoading)
        this.areSeancesLoadedArray = []
        const url = '/portal/api/entity-synthesis/' + entity.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.synthesisElements = resp.data.map((elt, index) => makeSeanceSynthesisElt(elt, index))
          this.synthesisElementsServices.clear()
          for (const elt of this.synthesisElements) {
            const key = '' + elt.youthHome.number + '-' + elt.seanceType.id
            const service = this.getPortalYouthHomeService(elt.youthHome, elt.seanceType)
            if (service) {
              this.synthesisElementsServices.set(key, service)
            }
          }
          this.initialized = true
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(this.synthesisLoading)
      }
    },
    async loadUserMessages() {
      const url = '/portal/api/user-messages/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.userMessages = resp.data.map((elt) => makeUserMessage(elt))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async loadPortalYouthHomeServices() {
      const url = '/portal/api/youth-home-services/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.youthHomeServices = resp.data.map((elt) => makePortalService(elt))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    getUserMessages(youthHome) {
      return this.userMessages.filter(
          elt => elt.youthHomeNumber === youthHome.number
      )
    },
    getGeneralUserMessages() {
      return this.userMessages.filter(
          elt => elt.youthHomeNumber === 0
      )
    },
    getPortalYouthHomeService(youthHome, seanceType) {
      const youthHomeServices = this.youthHomeServices.filter(
          elt => (
              (elt.youthHomeNumber === youthHome.number) &&
              ((elt.seanceTypeId === 0) || (elt.seanceTypeId === seanceType.id))
          )
      )
      if (youthHomeServices.length) {
        return youthHomeServices[0]
      }
      return null
    },
    hasService(elt) {
      const key = '' + elt.youthHome.number + '-' + elt.seanceType.id
      return this.synthesisElementsServices.has(key)
    },
    getService(elt) {
      const key = '' + elt.youthHome.number + '-' + elt.seanceType.id
      return this.synthesisElementsServices.get(key) || null
    },
    getOpening(elt) {
      const key = '' + elt.youthHome.number + '-' + elt.seanceType.id
      return this.synthesisElementsOpenings.get(key) || null
    },
    async showPriceError() {
      const alert = await alertController.create({
        header: 'Erreur',
        message: this.priceError,
        buttons: ['OK'],
      })
      await alert.present()
    },
    async calculateInscriptionsPrices(newInscriptions, cancellations) {
      if (this.entity && this.entity.id > 0) {
        this.startLoading(this.calculatePricesLoadingName)
        this.priceError = ''
        const data = []
        for (const inscription of newInscriptions) {
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: false,
            }
          )
        }
        for (const inscription of cancellations) {
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: true,
            }
          )
        }
        const url = '/portal/api/entity-seances-prices/' + this.entity.id + '/'
        const backendApi = new BackendApi('post', url)
        const prices = {}
        try {
          const resp = await backendApi.callApi(data)
          for (const elt of resp.data) {
            if (!elt.hide) {
              const priceKey = this.getPriceKey(elt.seance, elt.individual)
              prices[priceKey] = makeSeancePrice(elt)
            }
          }
          this.allPrices = { ...prices, }
        } catch (err) {
          this.priceError = this.getErrorText(err)
          this.addError(this.priceError)
        }
        this.forcePriceCalculation = false
        this.endLoading(this.calculatePricesLoadingName)
      }
    },
    async loadPrices() {
      await this.calculateInscriptionsPrices(this.newInscriptions, this.cancellations)
      for (const cancellation of this.cancellations) {
        const key = cancellation.getKey()
        // eslint-disable-next-line no-prototype-builtins
        if (!this.refundCancellations.hasOwnProperty(key)) {
          this.refundCancellations[key] = !this.isInvoiced(cancellation)
        }
      }
      this.refundCancellations = { ...this.refundCancellations, }
    },
    showSeances(elt) {
      const visibleIndex = this.areSeancesVisibleArray.indexOf(elt.index)
      if (visibleIndex >= 0) {
        this.areSeancesVisibleArray.splice(visibleIndex, 1)
      } else {
        this.areSeancesVisibleArray = [elt.index]
      }
    },
    onSeancesLoaded(elt, seances) {
      this.seances = this.seances.concat(seances)
      this.areSeancesLoadedArray = this.areSeancesLoadedArray.concat([elt.index])
    },
    onInscriptionChanged(data) {
      const seance = data.seance
      const individual = data.individual
      const inscription = data.inscription
      const key = this.getPriceKey(seance.id, individual.id)
      const individualId = +individual.id
      if (inscription && this.userDiscounts[individualId]) {
        this.allDiscounts[key] = { ...this.userDiscounts[individualId], }
      } else {
        this.allDiscounts[key] = null
      }
      const hasInscriptions = (this.cancellations.length) || (this.newInscriptions.length)
      this.$emit('inscriptions-changed', { hasInscriptions: hasInscriptions, })
    },
    onInscriptionsChanged() {
      this.forcePriceCalculation = true
      if (this.showInscriptions) {
        this.loadPrices()
      }
    },
    onShowInscriptions() {
      this.showInscriptions = true
      if (this.forcePriceCalculation) {
        this.loadPrices()
      }
    },
    getPriceKey(seanceId, individualId) {
      return '' + seanceId + '/' + individualId
    },
    getPriceObj(inscription) {
      const priceKey = this.getPriceKey(inscription.seance.id, inscription.individual.id)
      // eslint-disable-next-line no-prototype-builtins
      if (this.allPrices.hasOwnProperty(priceKey)) {
        return this.allPrices[priceKey]
      }
      return null
    },
    hasPrice(inscription) {
      const priceKey = this.getPriceKey(inscription.seance.id, inscription.individual.id)
      // eslint-disable-next-line no-prototype-builtins
      if (this.allPrices.hasOwnProperty(priceKey)) {
        return true
      }
      return false
    },
    getPrice(inscription) {
      const obj = this.getPriceObj(inscription)
      let price = obj ? obj.getPrice() : 0
      const workshop = inscription.seance.getWorkshop(inscription.individual.id)
      if (workshop && workshop.price) {
        price += workshop.price
      }
      return price
    },
    getMessage(inscription) {
      const obj = this.getPriceObj(inscription)
      return obj ? obj.message : ''
    },
    isInvoiced(inscription) {
      const obj = this.getPriceObj(inscription)
      return (obj !== null) ? obj.isInvoiced() : false
    },
    async onConfirmInscriptions() {
      if (this.entity && this.entity.id > 0) {
        this.startLoading(this.seancesCreationLoading)
        const data = []
        for (const inscription of this.getSelectedCancellations()) {
          const workshops = inscription.seance.getOriginalWorkshopInscriptions(inscription.individual.id)
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: true,
              refund: true, // always refund
              discounts: [],
              workshops: workshops.map(elt => { return { workshop: elt.workshop, moment: elt.moment, } }),
            }
          )
        }
        for (const inscription of this.getSelectedInscriptions()) {
          const workshops = inscription.seance.getWorkshopInscriptions(inscription.individual.id)
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: false,
              workshops: workshops.map(elt => { return { workshop: elt.workshop, moment: elt.moment, } }),
              discounts: [],
            }
          )
        }
        const hasAutoInvoice = this.autoInvoiceServices.length > 0
        const url = '/portal/api/entity-create-inscriptions/' + this.entity.id + '/'
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          const updatedSeances = resp.data.seances.map(elt => makeEntitySeance(elt))
          const existingSeanceIds = this.seances.map(elt => elt.id)
          for (const updatedSeance of updatedSeances) {
            const seanceIndex = existingSeanceIds.indexOf(updatedSeance.id)
            if (seanceIndex >= 0) {
              const seance = this.seances[seanceIndex]
              seance.inscriptionForIndividualIds = updatedSeance.inscriptionForIndividualIds
              seance.originalWorkshopInscriptions = updatedSeance.originalWorkshopInscriptions
            }
          }
          for (const seance of this.seances) {
            seance.resetChanges()
          }
          this.showInscriptions = false
          this.seances = [].concat(this.seances)
          this.$emit('inscriptions-saved', { autoInvoice: hasAutoInvoice, })
          this.addSuccess('Les inscriptions ont été prises en compte')
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(this.seancesCreationLoading)
      }
    },
    getSelectedInscriptions() {
      return this.newInscriptions
    },
    getSelectedCancellations() {
      return this.cancellations
    },
    getWelfare(inscription) {
      const priceKey = this.getPriceKey(inscription.seance.id, inscription.individual.id)
      if (priceKey in this.allPrices) {
        return this.allPrices[priceKey].welfare
      }
      return []
    },
    getWelfareSummary(inscription) {
      const welfare = this.getWelfare(inscription)
      const summary = []
      for (const item of welfare) {
        const index = summary.map(elt => elt.id).indexOf(item.id)
        if (item.amount && index >= 0) {
          summary[index].add(item)
        } else {
          summary.push(item.clone())
        }
      }
      return summary
    },
    getDiscount(inscription) {
      const discountKey = this.getPriceKey(inscription.seance.id, inscription.individual.id)
      if (discountKey in this.allDiscounts) {
        const discount = this.allDiscounts[discountKey]
        if (discount) {
          return [discount]
        }
      }
      return []
    },
    areSeancesVisible(elt) {
      return this.areSeancesVisibleArray.indexOf(elt.index) >= 0
    },
    areSeancesLoaded(elt) {
      return this.areSeancesLoadedArray.indexOf(elt.index) >= 0
    },
    canCreateSeances(elt) {
      return this.areSeancesLoaded(elt) || this.areSeancesVisible(elt)
    },
    getText(elt) {
      return htmlText(elt.text)
    },
    getInscriptionStyle(inscription) {
      if (inscription.seance.fixedFee) {
        return 'fixed-fee-seance'
      }
      return ''
    },
    getSeanceName(inscription) {
      if (inscription.seance.fixedFee && !store.getters.options.viewFixedFeeDates) {
        return inscription.seance.getShortName()
      }
      return inscription.seance.name
    },
    getStyle(elt) {
      if (elt.period.backgroundColor) {
        return {
          'background': elt.period.backgroundColor,
          'color': elt.period.textColor || '#000',
        }
      } else if (elt.seanceType.backgroundColor) {
        return {
          'background': elt.seanceType.backgroundColor,
          'color': elt.seanceType.textColor || '#000',
        }
      } else if (elt.youthHome.backgroundColor) {
        return {
          'background': elt.youthHome.backgroundColor,
          'color': elt.youthHome.textColor || '#000',
        }
      } else {
        return {
          'background': '#428cff',
          'color': "#fff",
        }
      }
    },
    async loadOpenings() {
      this.synthesisElementsOpenings.clear()
      this.startLoading(this.loadingName)
      const url = '/portal/api/service-openings/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        const openings = resp.data.map(elt => makePortalOpening(elt))
        for (const opening of openings) {
          const key = '' + opening.youthHome.number + '-' + opening.seanceType.id
          this.synthesisElementsOpenings.set(key, opening)
        }
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getLimits(inscription) {
      const key = getInscriptionLimitKey(inscription.seance.id, inscription.individual.id)
      if (this.limitsMap.has(key)) {
        return this.limitsMap.get(key)
      }
      return null
    },
    onLimitsLoaded(event) {
      this.hasLimits = event.limits.length
      this.hasLimitsCounter = false
      const limitsMap = new Map(this.limitsMap)
      for (const limit of event.limits) {
        limitsMap.set(limit.key(), limit)
        if (!limit.hideRemaining) {
          this.hasLimitsCounter = true
        }
      }
      this.limitsMap = limitsMap
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.synthesis-element {
  margin: 20px 10px;
  padding: 5px;
  border: solid 1px var(--ion-color-medium);
}
ion-item.synthesis-element-header {
  font-weight: bold;
  border-bottom: black 1px solid;
}
ion-item.synthesis-element-header:first-child{
  --border-style: none !important;
}
ion-item.synthesis-element-message {
  font-weight: bold;
}
.seances-list {
  border-top: solid 1px #888;
}
.small {
  font-size: 0.8em;
}
ion-button i {
  margin-right: 5px;
}
.inscriptions-block {
  margin: 10px;
  border: solid 1px var(--ion-color-dark);
  border-radius: 8px;
}
.inscriptions-block .header-line {
  padding: 5px;
  background: var(--ion-color-light-tint);
  border-radius: 8px 8px 0 0;
}
.inscriptions-block .field-line {
  padding: 5px;
  border-bottom: solid 1px var(--ion-color-dark);
}
.inscriptions-block .field-line:last-of-type {
  border-bottom: none;
}
.text-right {
  text-align: right;
}
.label {
  display: inline-block;
  font-size: 11px;
  border: solid 1px var(--ion-color-dark);
  background: var(--ion-color-light-tint);
  border-radius: 2px;
  padding: 2px 4px;
  margin-bottom: 1px;
  margin-right: 4px;
}
.fixed-fee-seance {
  background: var(--ion-color-secondary);
  color: #222;
}

.fixed-fee-seance a {
  color: #222;
}

.fixed-fee-seance .label {
  background: var(--ion-color-dark-tint);
}

.buttons-bar {
  text-align: center;
  margin-top: 5px;
}
.page {
  padding-bottom: 100px;
}
.text-center {
  text-align: center;
}
.message-card {
  padding: 4px;
}
.header-warning {
  padding: 5px;
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
  font-style: italic;
}
</style>
