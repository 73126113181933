<template>
  <span>
    {{ counter }} {{ labelText }}
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
export default {
  name: 'CounterLabel',
  props: {
    counter: Number,
    label: String,
    labelN: {
      type: String,
      defaultValue: '',
    },
  },
  computed: {
    labelText() {
      return (this.counter > 1) ? (this.labelN || this.label + 's') : this.label
    },
  },
}
</script>
<style scoped lang="less">
</style>
