<template>
  <span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { getInscriptionLimitKey, makeInscriptionLimitEx } from '@/types/youth'

export default {
  name: 'LimitsLoader',
  components: {
  },
  mixins: [BackendMixin],
  props: {
    inscriptions: Array,
  },
  emits: ['limits-loaded'],
  data() {
    return {
      limitsLoadingName: 'limitsLoading',
      cacheTs: new Map(),
      cacheData: new Map(),
    }
  },
  watch: {
    inscriptions: function() {
      this.loadLimits()
    },
  },
  computed: {
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    notInCache(inscription) {
      const key = getInscriptionLimitKey(inscription.seance.id, inscription.individual.id)
      if (this.cacheData.has(key)) {
        const diff = moment().diff(this.cacheTs.get(key), 'seconds')
        if (diff < 300) {
          return false
        }
      }
      return true
    },
    async loadLimits() {
      this.startLoading(this.limitsLoadingName)
      const data = {
        'inscriptions': this.inscriptions.filter(
          elt => !elt.seance.parent && this.notInCache(elt)
        ).map(
          elt => {
            return {
              seance: elt.seance.id,
              individual: elt.individual.id,
            }
          }
        ),
      }
      if (data.inscriptions.length) {
        const url = '/portal/api/seance-limits-check-batch/'
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          for (const limitElt of resp.data) {
            const elt = makeInscriptionLimitEx(limitElt)
            this.cacheTs.set(elt.key(), moment())
            this.cacheData.set(elt.key(), elt)
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
      const event = {
        limits: Array.from(this.cacheData.values()),
      }
      this.$emit('limits-loaded', event)
      this.endLoading(this.limitsLoadingName)
    },
  },
  mounted() {
    this.loadLimits()
  },
}
</script>
<style lang="less">
</style>
