<template>
  <span v-if="limits">
    <seance-limits-display
      :group-limits="limits.groupLimits"
      :day-limits="limits.dayLimits"
      :seance-group-limits="limits.seanceGroupLimits"
      :seance-limits="limits.seanceLimits"
      :excursion-group-limits="limits.excursionGroupLimits"
      :excursion-limits="limits.excursionLimits"
      :age-group="limits.ageGroup"
      :hide-remaining="limits.hideRemaining"
      :allow-waiting-list="limits.allowWaitingList"
    >
    </seance-limits-display>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { BackendMixin } from '@/mixins/backend'
import SeanceLimitsDisplay from '@/components/SeanceLimitsDisplay'

export default {
  name: 'LimitsDisplay',
  components: {
    SeanceLimitsDisplay,
  },
  mixins: [BackendMixin],
  props: {
    limits: Object,
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style lang="less">
</style>
