<template>
  <div>
    <div>
      <loading-gif :loading-name="seancesLoading"></loading-gif>
      <limits-loader
        :inscriptions="newInscriptions"
        @limits-loaded="onLimitsLoaded"
      >
      </limits-loader>
      <ion-row v-if="hasLimitsCounter" class="header-warning">
        <ion-col>Les limites n'incluent pas les inscriptions que vous venez de cocher</ion-col>
      </ion-row>
      <div>
        <ion-row v-if="dayChoices.length > 1" class="seance-filter">
          <ion-col>
            <div class="seance-filter-title">Filtrer par jour de semaine</div>
            <check-box-select
              id="dayChoices"
              :choices="dayChoices"
              :initial-value="dayChoices"
              :inline="true"
              @changed="selectedDays = $event.choices"
            ></check-box-select>
          </ion-col>
        </ion-row>
        <ion-row v-if="codeChoices.length > 1" class="seance-filter">
          <ion-col>
            <div class="seance-filter-title">Filtrer par type de séances</div>
            <check-box-select
              id="codeChoices"
              :choices="codeChoices"
              :initial-value="codeChoices"
              :inline="true"
              @changed="selectedCodes = $event.choices"
            ></check-box-select>
          </ion-col>
        </ion-row>
        <ion-row v-if="hiddenInscriptionsSeances.length" class="ins-warning-message">
          <ion-col>
            Attention! Vous avez
            <counter-label
              :counter="hiddenInscriptionsSeances.length"
              label="inscription caché"
              label-n="inscriptions cachées"
            ></counter-label>
            par les filtres sélectionnés
          </ion-col>
        </ion-row>
        <ion-row v-if="allIndividuals.length > 1" class="header-line">
          <ion-col cols="6">Cocher tous les enfants à la fois</ion-col>
          <ion-col cols="6">
            <div end class="text-right ion-text-wrap">
              <div class="checkbox-holder">
                <input
                  type="checkbox"
                  v-model="checkAllIndividuals"
                  class="inline"
                /><span></span>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
      <div>
        <ion-row v-if="filteredSeances.length" class="header-line">
          <ion-col cols="6">
            Tout cocher
          </ion-col>
          <ion-col cols="6">
            <div class="text-right">
              <check-all-button
                :value="allSeancesChecked()"
                @click="checkAllSeances($event)"
              ></check-all-button>
            </div>
          </ion-col>
        </ion-row>
        <div
          v-for="(seance, index) in filteredSeances"
          :key="seance.id"
          class="seance-line"
        >
          <div>
            <ion-row
              v-if="showByDays && newDayName(seance, index)"
              class="day-name-header"
            >
              <ion-col>{{ newDayName(seance, index) }}</ion-col>
            </ion-row>
            <ion-row :class="getSeanceClass(seance)" :style="getSeanceStyle(seance)">
              <ion-col cols="5">
                <span v-if="showByDays">{{ seance.getShortName() }}</span>
                <span v-else>{{ getSeanceName(seance) }}</span>
                <div class="seance-comments" v-if="seance.comments">{{ seance.comments }}</div>
              </ion-col>
              <ion-col cols="7">
                <div end class="text-right ion-text-wrap">
                  <div
                    v-for="individual in getSeanceIndividuals(seance)"
                    :key="individual.id"
                    class="line-bottom"
                  >
                    <span class="small-label label-danger" v-if="isInscriptionRefused(seance, individual)">
                      Refusée
                    </span>
                    <span class="small-label label-warning" v-else-if="isInscriptionToBeConfirmed(seance, individual)">
                      Attente confirmation
                    </span>
                    <span class="small-label label-warning" v-else-if="isCancellationToBeConfirmed(seance, individual)">
                      Attente annulation
                    </span>
                    <span class="small-label label-warning" v-else-if="isInscriptionWaiting(seance, individual)">
                      Sur liste d'attente
                    </span>
                    <span class="small-label label-success" v-else-if="isInscriptionConfirmed(seance, individual)">
                      Confirmée
                    </span>
                    <div class="small-label text-center label-danger" v-if="getForbiddenReason(seance, individual)">
                      {{ getForbiddenReason(seance, individual) }}
                    </div>
                    <a href @click.prevent="setIndividualInscription3($event, seance, individual)"
                    >
                      {{ individual.firstName }}
                    </a>
                    <div class="checkbox-holder">
                      <input
                        type="checkbox"
                        :checked="doesIndividualHaveInscription(seance, individual)"
                        @click="setIndividualInscription3($event, seance, individual)"
                        :disabled="!isInscriptionCheckboxActive(seance, individual)"
                        class="inline"
                      /><span></span>
                    </div>
                    <div v-if="isIndividualHaveNewInscription(seance, individual)">
                      <limits-display :limits="getLimits(seance, individual)">
                      </limits-display>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { IonRow, IonCol } from '@ionic/vue'
import { mapMutations, mapActions } from 'vuex'
import CheckAllButton from '@/components/Controls/CheckAllButton'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from "@/components/Controls/CounterLabel"
import LimitsLoader from '@/components/LimitsLoader'
import LimitsDisplay from '@/components/LimitsDisplay'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'
import store from '@/store'
import {getInscriptionLimitKey, makeEntitySeance, makeInscriptionRule, SeanceInscription} from '@/types/youth'
import {distinctElements, existsIn} from '@/utils/arrays'
import { BackendApi } from '@/utils/http'
import { capitalize } from '@/utils/strings'
import { diffDate } from '@/utils/dates'
import CheckBoxSelect from "@/components/Controls/CheckBoxSelect"
import { makeChoice } from "@/types/base"
import { compareNumbers } from "@/utils/sorting"


export default {
  name: 'entity-seances',
  components: {
    LimitsDisplay,
    CounterLabel,
    CheckBoxSelect,
    CheckAllButton,
    LoadingGif,
    LimitsLoader,
    IonRow, IonCol,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    youthHome: Object,
    seanceType: Object,
    period: Object,
    service: Object,
    elt: Object,
  },
  emits: [
    'inscription-changed', 'seances-loaded', 'limits-loaded'
  ],
  data() {
    return {
      seances: [],
      seancesLoading: 'seances-loading',
      inscriptionPossibilitiesMap: new Map(),
      groupColors: new Map(),
      frontColors: new Map(),
      rules: [],
      inscriptionsToBePaid: [],
      inscriptionsToBeConfirmed: [],
      inscriptionsRefused: [],
      inscriptionsWaiting: [],
      cancellationsToBeConfirmed: [],
      confirmedInscriptions: [],
      allChecked: 0,
      checkAllIndividuals: false,
      allIndividuals: [],
      limits: [],
      hasLimitsCounter: false,
      selectedDays: [],
      selectedCodes: [],
      codesMap: new Map(),
      codeChoices: [],
    }
  },
  computed: {
    isSynthesisLoading() {
      return store.getters.loading && store.getters.loading[this.seancesLoading]
    },
    dayChoices() {
      const days = []
      const dayIds = []
      for (const seance of this.seances) {
        const dayId = seance.dayId
        const dayName = moment(seance.date).format('dddd')
        if (dayIds.indexOf(dayId) < 0) {
          days.push(makeChoice({ id: dayId, name: dayName, }))
          dayIds.push(dayId)
        }
      }
      return days.sort(
        (elt1, elt2) => compareNumbers(elt1.id, elt2.id)
      )
    },
    showByDays() {
      if (this.youthHome && this.seanceType) {
        if (this.youthHome.overrideShowByDays) {
          return this.youthHome.showByDaysPortal
        } else {
          return this.seanceType.showByDaysPortal
        }
      }
      return false
    },
    days() {
      if (this.seances) {
        return distinctElements(this.seances.map(elt => elt.date)).sort()
      }
      return []
    },
    filteredSeances() {
      const selectDaysIds = this.selectedDays.map(elt => elt.id)
      const selectCodes = []
      for (const code of this.selectedCodes) {
        selectCodes.push(this.codesMap.get(code.id))
      }
      return this.seances.filter(
        elt => (
          existsIn([elt.dayId], selectDaysIds) &&
          existsIn([elt.getCodeName()], selectCodes)
        )
      )
    },
    hiddenInscriptionsSeances() {
      const filteredIds = this.filteredSeances.map(elt => elt.id)
      return this.seances.filter(
        elt => (
          !existsIn([elt.id], filteredIds) &&
          elt.hasInscriptions()
        )
      )
    },
    newInscriptions() {
      const newInscriptions = []
      const avoidDuplicates = new Map()
      for (const seance of this.seances) {
        if (seance.hasNewInscriptions()) {
          for (const individual of this.entity.getMainIndividuals()) {
            if (seance.isIndividualInscriptionNew(individual.id)) {
              const key = '' + seance.id + ':' + individual.id
              if (!avoidDuplicates.has(key)) {
                avoidDuplicates.set(key, 1)
                newInscriptions.push(new SeanceInscription(0, seance, individual))
              }
            }
          }
        }
      }
      return newInscriptions
    },
  },
  created() {
    this.initialize()
  },
  watch: {
    entity: function() {
      this.initialize()
    },
    filteredSeances : function() {
      if (this.filteredSeances.length) {
        const seance = this.filteredSeances[0]
        this.allIndividuals = this.getSeanceIndividuals(seance)
      } else {
        this.allIndividuals = []
      }
    },
    seances() {
      this.codeChoices = this.buildCodeChoices()
      this.selectedCodes = this.codeChoices
      this.selectedDays = this.dayChoices
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(
        ['startLoading', 'endLoading', 'startEntityEdition', 'endEntityEdition']
    ),
    getStyle(elt) {
      if (elt.period.backgroundColor) {
        return {
          '--background': elt.period.backgroundColor,
          '--color': elt.period.textColor || '#000',
        }
      } else if (elt.seanceType.backgroundColor) {
        return {
          '--background': elt.seanceType.backgroundColor,
          '--color': elt.seanceType.textColor || '#000',
        }
      } else if (elt.youthHome.backgroundColor) {
        return {
          '--background': elt.youthHome.backgroundColor,
          '--color': elt.youthHome.textColor || '#000',
        }
      } else {
        return {
          '--background': '#428cff',
          '--color': "#fff",
        }
      }
    },
    getColorText(elt) {
      if (elt.period.backgroundColor) {
        return {
          'color': elt.period.textColor || '#000',
        }
      } else if (elt.seanceType.backgroundColor) {
        return {
          'color': elt.seanceType.textColor || '#000',
        }
      } else if (elt.youthHome.backgroundColor) {
        return {
          'color': elt.youthHome.textColor || '#000',
        }
      } else {
        return {
          'color': "#fff",
        }
      }
    },
    getSeanceKey(seanceId, individualId) {
      return '' + seanceId + '#' + individualId
    },
    async initialize() {
      await this.loadSeances(this.entity)
      await this.loadInscriptionRules(this.entity)
      await this.loadInscriptionsStatus(this.entity)
      this.allChecked = this.getAllSeancesChecked()
    },
    async loadSeances(entity) {
      if (entity && entity.id) {
        this.startLoading(this.seancesLoading)
        const url = '/portal/api/entity-seances/' + entity.id + '/' + this.youthHome.id + '/' +
            this.seanceType.id + '/' + this.period.id + '/'
        const backendApi = new BackendApi('get', url)
        this.confirmedInscriptions = []
        try {
          const resp = await backendApi.callApi()
          const seances = resp.data.map(elt => makeEntitySeance(elt))
          this.seances = []
          for (const seance of seances) {
            const seanceIndividuals = this.getSeanceIndividuals(seance)
            if (seanceIndividuals.length) {
              for (const individual of seanceIndividuals) {
                if (seance.doesIndividualHaveInscription(individual.id)) {
                  const key = this.getSeanceKey(seance.id, individual.id)
                  this.confirmedInscriptions.push(key)
                }
              }
              this.seances.push(seance)
            }
          }
          this.$emit('seances-loaded', this.seances)
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(this.seancesLoading)
      }
    },
    async loadInscriptionsStatus(entity) {
      if (entity && entity.id) {
        this.startLoading(this.seancesLoading)
        const url = '/portal/api/entity-inscriptions-status/' + entity.id + '/' + this.youthHome.id + '/' +
            this.seanceType.id + '/' + this.period.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.inscriptionsRefused = resp.data.filter(elt => (!elt.cancelled) && elt.refused && !elt.waiting).map(
              elt => this.getSeanceKey(elt.seance, elt.individual)
          )
          this.inscriptionsWaiting = resp.data.filter(elt => (!elt.cancelled) && elt.refused && elt.waiting).map(
              elt => this.getSeanceKey(elt.seance, elt.individual)
          )
          this.inscriptionsToBeConfirmed = resp.data.filter(elt => (!elt.cancelled) && (!elt.refused)).map(
              elt => this.getSeanceKey(elt.seance, elt.individual)
          )
          this.inscriptionsToBePaid = resp.data.filter(elt => elt.payment_warning).map(
              elt => this.getSeanceKey(elt.seance, elt.individual)
          )
          this.cancellationsToBeConfirmed = resp.data.filter(elt => elt.cancelled && (!elt.confirmed)).map(
              elt => this.getSeanceKey(elt.seance, elt.individual)
          )
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(this.seancesLoading)
      }
    },
    async loadInscriptionRules() {
      if (this.entity && this.entity.id > 0) {
        const url = '/portal/api/inscription-rules/' + this.seanceType.id + '/' + this.youthHome.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.rules = resp.data.map(elt => makeInscriptionRule(elt))
          this.initializeRules()
          this.initializeRulesColors()
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    getSeanceIndividuals(seance) {
      const individuals = []
      for (const individual of this.entity.getMainIndividuals()) {
        if (seance.availableForIndividualIds.indexOf(individual.id) >= 0) {
          individuals.push(individual)
        }
      }
      return individuals
    },
    isIndividualHaveNewInscription(seance, individual) {
      return seance.isIndividualInscriptionNew(individual.id)
    },
    doesIndividualHaveInscription(seance, individual) {
      return seance.doesIndividualHaveInscription(individual.id)
    },
    setIndividualInscription(seance, individual) {
      const inscription = seance.toggleIndividualInscription(individual.id)
      this.notifyInscriptionChanged(seance, individual, inscription)
      if (inscription) {
        // si inscription : on décoche des séances qui ne peuvent pas correspondre
        const linkedSeances = this.getLinkedSeances(seance)
        let linkedChildren = []
        for (const linkedSeance of linkedSeances) {
          const parent = this.getParentSeance(linkedSeance)
          if (parent) {
            parent.resetIndividualInscription(individual.id)
            this.notifyInscriptionChanged(parent, individual, false)
            linkedChildren = linkedChildren.concat(this.getChildrenSeances(parent))
          } else {
            linkedSeance.resetIndividualInscription(individual.id)
            this.notifyInscriptionChanged(linkedSeance, individual, false)
            linkedChildren = linkedChildren.concat(this.getChildrenSeances(linkedSeance))
          }
        }
        for (const linkedSeance of linkedChildren) {
          // désinscrire pour cette séance
          linkedSeance.resetIndividualInscription(individual.id)
          this.notifyInscriptionChanged(linkedSeance, individual, false)
        }
      }
      // modifie les séances "filles" (exemple journées suivantes d'un camp)
      const childrenSeances = this.getChildrenSeances(seance)
      let childLinked = []
      for (const childSeance of childrenSeances) {
        if (inscription) {
          childSeance.setIndividualInscription(individual.id)
          childLinked = childLinked.concat(this.getLinkedSeances(childSeance))
        } else {
          childSeance.resetIndividualInscription(individual.id)
        }
        this.notifyInscriptionChanged(childSeance, individual, inscription)
      }
      for (const linkedSeance of childLinked) {
        // désinscrire pour cette séance
        linkedSeance.resetIndividualInscription(individual.id)
        this.notifyInscriptionChanged(linkedSeance, individual, false)
      }
      // modifie les séances qui en dépendent (exemple forfait)
      let allSecondarySeances = this.getSecondarySeances(seance)
      const dependencySeances = this.getDependencySeances(seance)
      for (const dependencySeance of dependencySeances) {
        allSecondarySeances = allSecondarySeances.concat(this.getSecondarySeances(dependencySeance))
        if (inscription) {
          dependencySeance.setIndividualInscription(individual.id)
        } else {
          dependencySeance.resetIndividualInscription(individual.id)
        }
        this.notifyInscriptionChanged(dependencySeance, individual, inscription)
      }
      for (const secondarySeance of allSecondarySeances) {
        if (inscription) {
          this.markInscriptionPossible(secondarySeance, individual, true)
        } else {
          // désinscrire pour cette séance
          secondarySeance.resetIndividualInscription(individual.id)
          this.notifyInscriptionChanged(secondarySeance, individual, false)
          this.markInscriptionPossible(secondarySeance, individual, false)
        }
      }
      return inscription
    },
    markInscriptionPossible(seance, individual, value) {
      const key = '' + seance.id + '-' + individual.id
      this.inscriptionPossibilitiesMap.set(key, value)
    },
    getForbiddenReason(seance, individual) {
      if (seance.isIndividualInscriptionDoneByOther(individual.id)) {
        return 'Autre famille'
      }
      return seance.isIndividualInscriptionForbidden(individual.id)
    },
    isInscriptionCheckboxActive(seance, individual) {
      if (seance.parent) {
        return false
      }
      if (seance.isIndividualInscriptionDoneByOther(individual.id)) {
        return false
      }
      if (seance.isIndividualInscriptionForbidden(individual.id)) {
        return false
      }
      const today = moment().format('YYYY-MM-DD')
      const diff = diffDate(seance.date, today)
      if (
          (!this.service.allowCancellation) ||
          (this.service.cancelLimitDays && (diff <= this.service.cancelLimitDays))
      ) {
        // Si les annulations ne sont pas autorisées
        if (this.isInscriptionKnown(seance, individual)) {
          // On ne peut pas décocher uen séance pour laquelle une inscription est en cours
          return false
        }
        const linkedSeances = this.getLinkedSeances(seance)
        for (const linkedSeance of linkedSeances) {
          // Ni cocher une autre séance liée
          if (this.isInscriptionKnown(linkedSeance, individual)) {
            return false
          }
        }
      }
      const key = '' + seance.id + '-' + individual.id
      if (this.inscriptionPossibilitiesMap.has(key)) {
        return this.inscriptionPossibilitiesMap.get(key)
      }
      return true
    },
    setIndividualInscription3(event, seance, individual) {
      // changer l'inscription du membre cliqué
      if (this.isInscriptionCheckboxActive(seance, individual)) {
        const inscription = this.setIndividualInscription(seance, individual)
        if (this.checkAllIndividuals) {
          // Si la touche Shift est enfoncée : les autres membres de la famille
          // auront la même inscription
          for (const sibling of this.allIndividuals) {
            if (sibling.id !== individual.id) {
              this.forceInscription(seance, sibling, inscription)
            }
          }
        }
      }
    },
    forceInscription(seance, individual, value) {
      const inscription = seance.doesIndividualHaveInscription(individual.id)
      if (inscription !== value) {
        this.setIndividualInscription(seance, individual)
      }
    },
    getLinkedSeances(seance) {
      const linkedSeances = []
      for (const elt of this.seances) {
        if ((seance.date === elt.date) && (elt.id !== seance.id)) {
          for (const rule of this.rules) {
            if (
              (rule.seanceCodes.indexOf(seance.getCodeName()) >= 0) &&
              (rule.seanceCodes.indexOf(elt.getCodeName()) >= 0)
            ) {
              linkedSeances.push(elt)
            }
          }
        }
      }
      return linkedSeances
    },
    initializeRules() {
      for (const seance of this.seances) {
        const primarySeances = this.getPrimarySeances(seance)
        if (primarySeances.length) {
          for (const individual of this.getSeanceIndividuals(seance)) {
            let hasPrimarySelected = false
            for (let i = 0; (i < primarySeances.length) && !hasPrimarySelected; i++) {
              const primarySeance = primarySeances[i]
              if (primarySeance.doesIndividualHaveInscription(individual.id)) {
                hasPrimarySelected = true
              }
            }
            if (!hasPrimarySelected) {
              this.markInscriptionPossible(seance, individual, false)
            }
          }
        }
      }
    },
    getChildrenSeances(seance) {
      return this.seances.filter(elt => seance.children.indexOf(elt.id) >= 0)
    },
    getParentSeance(seance) {
      if (seance.parent) {
        const parents = this.seances.filter(elt => elt.id === seance.parent)
        if (parents.length) {
          return parents[0]
        }
      }
      return null
    },
    getDependencySeances(seance) {
      return this.seances.filter(elt => seance.dependencies.indexOf(elt.id) >= 0)
    },
    getDailySeances(seance) {
      const dailySeances = []
      for (const elt of this.seances) {
        if ((seance.date === elt.date) && (elt.id !== seance.id)) {
          dailySeances.push(elt)
        }
      }
      return dailySeances
    },
    getRule(seance) {
      return this.getRuleFromCode(seance.getCodeName())
    },
    getRuleFromCode(code) {
      for (const rule of this.rules) {
        if (rule.match(code)) {
          return rule
        }
      }
      return null
    },
    getPrimarySeances(seance) {
      const primarySeances = []
      const seanceRule = this.getRule(seance)
      if (seanceRule && !seanceRule.isMain) {
        const seances = seanceRule.daily ? this.getDailySeances(seance) : this.seances
        for (const elt of seances) {
          const rule = this.getRule(elt)
          if (rule && rule.isMain) {
            primarySeances.push(elt)
          }
        }
      }
      return primarySeances
    },
    getSecondarySeances(seance) {
      const secondarySeances = []
      const seanceRule = this.getRule(seance)
      if (seanceRule && seanceRule.isMain) {
        const seances = seanceRule.daily ? this.getDailySeances(seance) : this.seances
        for (const elt of seances) {
          const rule = this.getRule(elt)
          if (rule && !rule.isMain) {
            secondarySeances.push(elt)
          }
        }
      }
      return secondarySeances
    },
    initializeRulesColors() {
      const primaryColors = [
        '#a1d6ef', '#a9e0a9', '#ffbebe', '#fff2b3',
        '#ffb1ff', '#ffc98f', '#b78dff', '#8fc993'
      ]
      const secondaryColors = [
        '#d8f0ff', '#d9ffd9', '#ffd9d9', '#fff8d9',
        '#ffe6ff', '#ffe9d9', '#dcc8eb', '#f0f0f0'
      ]
      const primaryFrontColors = [
          '#000', '#000', '#000', '#000',
          '#000', '#000', '#000', '#000'
      ]
      const secondaryFrontColors = [
          '#000', '#000', '#000', '#000',
          '#000', '#000', '#000', '#000'
      ]
      let primaryCounter = 0
      let secondaryCounter = 0
      for (const rule of this.rules) {
        if (rule.isMain) {
          this.groupColors.set(rule.id, primaryColors[primaryCounter])
          this.frontColors.set(rule.id, primaryFrontColors[primaryCounter])
          primaryCounter += 1
          if (primaryCounter >= primaryColors.length) {
            primaryCounter = 0
          }
        } else {
          this.groupColors.set(rule.id, secondaryColors[secondaryCounter])
          this.frontColors.set(rule.id, secondaryFrontColors[primaryCounter])
          secondaryCounter += 1
          if (secondaryCounter >= secondaryColors.length) {
            secondaryCounter = 0
          }
        }
      }
    },
    getSeanceColor(seance) {
      if (seance.fixedFee) {
        return '#bb96ff'
      }
      const rule = this.getRule(seance)
      if (rule) {
        if (this.groupColors.has(rule.id)) {
          return this.groupColors.get(rule.id)
        }
      }
      return 'transparent'
    },
    getSeanceFrontColor(seance) {
      if (seance.fixedFee) {
        return '#fff'
      }
      const rule = this.getRule(seance)
      if (rule) {
        if (this.frontColors.has(rule.id)) {
          return this.frontColors.get(rule.id)
        }
      }
      return 'default'
    },
    getSeanceName(seance) {
      if (seance.fixedFee && !store.getters.options.viewFixedFeeDates) {
        return seance.getShortName()
      }
      return seance.name
    },
    newDayName(seance, index) {
      const isNew = (index === 0) || (seance.date !== this.filteredSeances[index - 1].date)
      if (isNew) {
        return moment(seance.date).format('dddd DD MMMM YYYY')
      }
      return ''
    },
    getSeanceClass(seance) {
      if (seance.fixedFee) {
        return 'fixed-fee-seance'
      }
      const rule = this.getRule(seance)
      if (rule) {
        if (rule.isMain) {
          return 'primary-seance'
        } else {
          return 'secondary-seance'
        }
      }
      return ''
    },
    getSeanceStyle(seance) {
      return {
        background: this.getSeanceColor(seance),
        color: this.getSeanceFrontColor(seance),
      }
    },
    getCodeColor(code) {
      const rule = this.getRuleFromCode(code)
      if (rule) {
        if (this.groupColors.has(rule.id)) {
          return this.groupColors.get(rule.id)
        }
      }
      return 'transparent'
    },
    notifyInscriptionChanged(seance, individual, inscription) {
      this.$emit(
        'inscription-changed',
        {
          seance: seance,
          individual: individual,
          inscription: inscription,
        }
      )
      this.allChecked = this.getAllSeancesChecked()
    },
    isInscriptionToBePaid(seance, individual) {
      const key = this.getSeanceKey(seance.id, individual.id)
      return this.inscriptionsToBePaid.indexOf(key) >= 0
    },
    isInscriptionRefused(seance, individual) {
      const key = this.getSeanceKey(seance.id, individual.id)
      return this.inscriptionsRefused.indexOf(key) >= 0
    },
    isInscriptionWaiting(seance, individual) {
      const key = this.getSeanceKey(seance.id, individual.id)
      return this.inscriptionsWaiting.indexOf(key) >= 0
    },
    isInscriptionToBeConfirmed(seance, individual) {
      const key = this.getSeanceKey(seance.id, individual.id)
      return this.inscriptionsToBeConfirmed.indexOf(key) >= 0
    },
    isInscriptionConfirmed(seance, individual) {
      const key = this.getSeanceKey(seance.id, individual.id)
      return this.confirmedInscriptions.indexOf(key) >= 0
    },
    isInscriptionKnown(seance, individual) {
      // l'inscription est connue par le backend
      return this.isInscriptionConfirmed(seance, individual) || this.isInscriptionToBeConfirmed(seance, individual)
    },
    isCancellationToBeConfirmed(seance, individual) {
      const key = this.getSeanceKey(seance.id, individual.id)
      return this.cancellationsToBeConfirmed.indexOf(key) >= 0
    },
    dayName(day) {
      return capitalize(dateToString(day, 'dddd ')) + dateToString(day, 'Do ')
      + capitalize(dateToString(day, 'MMMM ')) + dateToString(day, 'YYYY')
    },
    doesDayHaveInscriptions(day) {
      for (const seance of this.seances) {
        if ((seance.date === day) && (seance.hasInscriptions())){
          return true
        }
      }
      return false
    },
    getAllSeancesChecked() {
      if (this.filteredSeances.length) {
        let hasAll = true
        let hasNone = true
        for (const seance of this.filteredSeances) {
          for (const individual of this.getSeanceIndividuals(seance)) {
            if (this.isInscriptionCheckboxActive(seance, individual)) {
              const hasInscription = seance.doesIndividualHaveInscription(individual.id)
              if (!hasInscription && hasAll) {
                hasAll = false
              }
              if (hasInscription && hasNone) {
                hasNone = false
              }
              if (!hasNone || !hasAll) {
                break
              }
            }
          }
        }
        return hasAll ? 1 : (hasNone ? 0 : 2)
      }
      return -1
    },
    allSeancesChecked() {
      return this.getAllSeancesChecked()
    },
    checkAllSeances(event) {
      for (const seance of this.filteredSeances) {
        for (const individual of this.getSeanceIndividuals(seance)) {
          if (this.isInscriptionCheckboxActive(seance, individual)) {
            this.forceInscription(seance, individual, (event.value === 0))
          }
        }
      }
    },
    onLimitsLoaded(event) {
      this.limits = event.limits
      this.hasLimitsCounter = false
      this.limitsMap = new Map()
      for (const limit of event.limits) {
        this.limitsMap.set(limit.key(), limit)
        if (!limit.hideRemaining) {
          this.hasLimitsCounter = true
        }
      }
      this.$emit('limits-loaded', event)
    },
    getLimits(seance, individual) {
      const key = getInscriptionLimitKey(seance.id, individual.id)
      if (this.limitsMap.has(key)) {
        return this.limitsMap.get(key)
      }
      return null
    },
    buildCodeChoices() {
      const codes = []
      let nextId = 1
      const idToCodes = new Map()
      const codesToId = new Map()
      for (const seance of this.seances) {
        const code = seance.getCodeName()
        const shortName = seance.getBaseName()
        if (!codesToId.has(code)) {
          codes.push(makeChoice({ id: nextId, name: shortName, }))
          codesToId.set(code, nextId)
          idToCodes.set(nextId, code)
          nextId += 1
        }
      }
      this.codesMap = idToCodes
      return codes
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-right {
  text-align: right;
}
.inline {
  display: inline-block;
  margin-left: 3px;
}
.seance-line {
  padding: 5px;
  border-bottom: solid 1px var(--ion-color-medium);
}

.line-bottom {
  padding-bottom: 2px;
  margin-bottom: 2px;
  border-bottom: solid 1px var(--ion-color-medium);
}

.line-bottom:last-of-type {
  border-bottom: none;
}

.header-line {
  padding: 5px;
  color: var(--ion-color-medium);
  font-style: italic;
  border-bottom: var(--ion-color-medium) solid 1px;
}

.header-warning {
  padding: 5px;
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
  font-style: italic;
}

.header-highlight {
  font-weight: bold;
  font-style: normal;
}

.seance-line:nth-of-type(odd) {
  background: var(--ion-color-dark-contrast);
}
.seance-line:last-of-type {
  border-bottom: none;
}
.small-label {
  display: inline-block;
  font-size: 0.8em;
  border-radius: 4px;
  padding: 2px;
  margin: 1px 4px;
}

.label-danger {
  background: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.label-warning {
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}

.label-success {
  background: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

.primary-seance {
  background: #fff;
  color: #222;
}

.primary-seance-1 {
  background: rgb(172, 223, 248);
  color: #222;
}

.primary-seance-2 {
  background: rgb(255, 162, 162);
  color: #222;
}

.primary-seance-3 {
  background: rgb(251, 255, 141);
  color: #222;
}

.primary-seance a{
  color: #222;
}

.fixed-fee-seance {
  background: #4e839f;
    color: #fff;
}

.fixed-fee-seance a {
  color: #222;
}

.secondary-seance {
  background: #888;
  color: #fff;
}

.secondary-seance-1 {
  background: #c3a597;
  color: #fff;
}

.secondary-seance-2 {
  background: #74ab6b;
  color: #fff;
}

.secondary-seance-3 {
  background: #a995c0;
  color: #fff;
}

.buttons-bar {
  text-align: center;
  margin-top: 20px;
}
.day-header {
  font-weight: bold;
}
.day-header a {
  color: var(--ion-color-primary-contrast)
}
ion-button i {
  margin-right: 5px;
}
.seance-comments {
  font-size: 10px;
}
.text-center {
  text-align: center;
}
ion-item.show-by-days{
  border-bottom: black 1px solid;
}
ion-item.show-by-days:first-child{
  --border-style: none !important;
}
.day-name-header {
  background: #06668b;
  color: #fff;
  margin-bottom: 2px;
  font-weight: bold;
}
.ins-warning-message {
  background: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}
.seance-filter {
  margin-bottom: 2px;
  padding-bottom: 2px;
  border-bottom: solid 1px #888;
}
.seance-filter-title {
  font-size: 12px;
  margin-bottom: 1px;
  padding-bottom: 1px;
  border-bottom: solid 1px #888;
}
</style>
