<template>
  <div>
    <div v-if="choices.length > 1 && !hideAll" class="check-all" :class="cssClasses" >
      <div class="checkbox-holder">
        <input
           type="checkbox"
          :id="id + 'check-all-choices'"
          v-model="allSelected"
           @change="checkAll()"
        /><span></span>
        Tous
      </div>
    </div>
    <div
        v-for="choice of choices"
        :key="choice.id"
        :class="cssClasses"
        :style="getStyle(choice)"
    >
      <div class="checkbox-holder">
        <input
          type="checkbox"
          :id="id + 'check-choice' + choice.id"
          :disabled="isChoiceDisabled(choice)"
          v-model="selectedChoices[choice.id]"
          @change="checkChoice(choice)"
        /><span></span>
        {{ getName(choice) }}
        <div v-if="descriptionCallback" class="checkbox-description">
          {{ descriptionCallback(choice) }}
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
export default {
  name: 'CheckBoxSelect',
  props: {
    choices: Array,
    inline: {
      type: Boolean,
      defaultValue: false,
    },
    box: {
      type: Boolean,
      defaultValue: false,
    },
    lineBottom: {
      type: Boolean,
      defaultValue: false,
    },
    hideAll: {
      type: Boolean,
      defaultValue: false,
    },
    descriptionCallback: {
      type: Function,
      defaultValue: null,
    },
    styleCallback: {
      type: Function,
      defaultValue: null,
    },
    nameCallback: {
      type: Function,
      defaultValue: null,
    },
    initialValue: {
      type: Array,
      defaultValue: [],
    },
    disabledChoices: {
      type: Array,
      defaultValue: [],
    },
    id: {
      type: String,
      defaultValue: '',
    },
  },
  data() {
    return {
      allSelected: false,
      selectedChoices: {},
    }
  },
  watch: {
    initialValue: function() { this.initChoices() },
    disabledChoices: function() { this.updateChoices() },
    selectedChoices() {
      let all = true
      for (const key of Object.keys(this.selectedChoices)) {
        if (!this.selectedChoices[key]) {
          all = false
          break
        }
      }
      this.allSelected = all
    },
  },
  computed: {
    cssClasses() {
      return { inline: this.inline, box: this.box, lineBottom: this.lineBottom, }
    },
    cssStyle() {
      return { inline: this.inline, box: this.box, lineBottom: this.lineBottom, }
    },
  },
  methods: {
    emitChange() {
      const choices = this.choices.filter(elt => this.isChoiceChecked(elt))
      this.$emit('changed', { choices, all: choices.length === this.choices.length, })
    },
    initChoices() {
      const selectedChoices = {}
      for (const choice of this.choices) {
          selectedChoices[choice.id] = false
        }
      if (typeof this.initialValue !== 'undefined') {
        for (const initialItem of this.initialValue) {
          selectedChoices[initialItem.id] = true
        }
      }
      this.selectedChoices = selectedChoices
      const choices = this.choices.filter(elt => this.isChoiceChecked(elt))
      this.$emit('init', { choices, all: choices.length === this.choices.length, })
    },
    updateChoices() {
      const selectedChoices = { ...this.selectedChoices, }
      if (typeof this.disabledChoices !== 'undefined') {
        for (const initialId of this.disabledChoices) {
          selectedChoices[initialId] = false
        }
      }
      this.selectedChoices = selectedChoices
    },
    isChoiceChecked(choice) {
      if (!this.isChoiceDisabled(choice) && this.selectedChoices[choice.id]) {
        return this.selectedChoices[choice.id]
      }
      return false
    },
    isChoiceDisabled(choice) {
      if (typeof this.disabledChoices !== 'undefined') {
        return this.disabledChoices.indexOf(choice.id) >= 0
      }
      return false
    },
    isAllChecked() {
      if (!this.hideAll) {
        for (const choice of this.choices) {
          if (!this.isChoiceDisabled(choice) && !this.isChoiceChecked(choice)) {
            return false
          }
        }
      }
      return true
    },
    checkChoice(choice) {
      const value = event.target.value
      this.selectedChoices = { ...this.selectedChoices, }
      this.emitChange()
    },
    checkAll() {
      const value = this.allSelected
      for (const choice of this.choices) {
        if (!this.isChoiceDisabled(choice)) {
          this.selectedChoices[choice.id] = value
        }
      }
      this.selectedChoices = { ...this.selectedChoices, }
      this.emitChange()
    },
    getName(elt) {
      if (this.nameCallback) {
        return this.nameCallback(elt)
      } else {
        return elt.name
      }
    },
    getStyle(elt) {
      if (this.styleCallback) {
        return this.styleCallback(elt)
      } else {
        return ''
      }
    },
  },
  mounted() {
    this.initChoices()
  },
}
</script>
<style scoped>
.inline {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 2px;
}
.box {
  margin-right: 5px;
  border: solid 1px #ccc;
  padding: 2px 4px;
}
.lineBottom {
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.lineBottom:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.check-all{
  color: #888;
}
.checkbox-description {
  font-size: 10px;
}
</style>
